import {useCallback, useEffect, useMemo, useState} from "react";
import useThrottle from "./useThrottle";
import {DEVICE_SIZE} from '@/types/interfaces/primitives';

const MEDIUM_DEVICE_MAX_WIDTH = 1440;
const TABLET_DEVICE_MAX_WIDTH = 1024;
const MOBILE_DEVICE_MAX_WIDTH = 768;
const DEVICE_SMALL_WIDTH = 375;
const THROTTLE_DELAY = 0;

const getDeviseSize = (windowWidth: number) => {
  if(windowWidth < DEVICE_SMALL_WIDTH) {
    return DEVICE_SIZE.SMALL;
  } else if(windowWidth < MOBILE_DEVICE_MAX_WIDTH) {
    return DEVICE_SIZE.MOBILE;
  } else if(windowWidth < TABLET_DEVICE_MAX_WIDTH) {
    return DEVICE_SIZE.TABLET;
  } else if(windowWidth < MEDIUM_DEVICE_MAX_WIDTH) {
    return DEVICE_SIZE.MEDIUM;
  } else {
    return DEVICE_SIZE.BIG;
  }
}

const useDetectDeviceWidth = (
    initIsMobileSize: boolean = global?.innerWidth < MOBILE_DEVICE_MAX_WIDTH,
    initIsSmallSize: boolean = global?.innerWidth <= DEVICE_SMALL_WIDTH,
  ) => {
  const [isMobileSize, setMobileSize] = useState(initIsMobileSize);
  const [isSmallSize, setSmallSize] = useState(initIsSmallSize);
  const [deviceSize, setDeviceSize] = useState(() => getDeviseSize(global?.innerWidth));

  const throttle = useThrottle(THROTTLE_DELAY);
  const throttleSmall = useThrottle(THROTTLE_DELAY);
  const throttleSize = useThrottle(THROTTLE_DELAY);

  const resizeWindow = useCallback(() => {
    const width = global.innerWidth;

    throttle(() => setMobileSize(width < MOBILE_DEVICE_MAX_WIDTH), "width");
    throttleSmall(() => setSmallSize(width <= DEVICE_SMALL_WIDTH), "small-width");
    throttleSize(() => setDeviceSize(getDeviseSize(width)), "device-width");
  }, [throttle, throttleSize, throttleSmall]);

  useEffect(() => {
    global.addEventListener("resize", resizeWindow);

    return () => global.removeEventListener("resize", resizeWindow);
  }, [resizeWindow]);

  return useMemo(() => ({ isMobileSize, isSmallSize, deviceSize }), [isMobileSize, isSmallSize, deviceSize]);
};

export default useDetectDeviceWidth;
