import { useMemo } from "react";
import { setCookieLocale } from "@/utils/cookie";
import useClientRouter from "@/hooks/useClientRouter";
import { IApiLanguage } from "@/types/interfaces/primitives";

interface ArgsLanguages {
  locales?: string[];
}

const useLanguages = (args: ArgsLanguages, languages: IApiLanguage[], onReload: (key: any) => void) => {
  const { locales = [] } = args;
  const router = useClientRouter();

  const data = useMemo(
    () => ({
      data: languages,
      list: locales.map((code) => ({
        value: code,
        label: `${code}`.toUpperCase(),
      })),
      main: languages.find(i => i.is_main),
      onChange: (code: string) => {
        setCookieLocale(code);
        router.replace(router.asPath, { locale: code, shallow: true });
        onReload(Date.now);
      },
      onChangeSeller: (code: string) => {
        setCookieLocale(code);
        onReload(Date.now);
      }
    }),
    [languages, locales, onReload, router]
  );

  return data;
};

export default useLanguages;
