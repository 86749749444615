"use client";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Cache, useSWRConfig } from "swr";
import { IS_SSR, typeToken } from "@/constants/common";
import { CustomCache, CustomSWRConfig } from "@/libs/swr";
import authRequests from "@/modules/auth/requests";
import { deleteCookieAuth, getCookie } from "@/utils/cookie";
import tokenStorage from "@/utils/token";
import routesSeller from "@/modules/seller/constants/routesSeller";
import sellerRequests from "@/modules/seller/requests";
import routesAdmin from "@/modules/admin/constants/routesAdmin";

const TIMEOUT_REFRESH_TOKEN = 5 * 60 * 1000;

const autoRefreshToken = (onUnauthorized: () => void, onTokenRefreshed: () => void) => {
  const refresh = tokenStorage.getRefreshToken();

  if (refresh) {
    authRequests
      .tokenRefresh({ refresh })
      .then(({ access }: any) => {
        tokenStorage.set({ token: access, tokenRefresh: refresh }, !tokenStorage.isSaveInSession());
        onTokenRefreshed();
      })
      .catch((err) => {
        if (err?.code === "token_not_valid") {
          tokenStorage.erase();
          onUnauthorized();
        }
      });
  }
};

const useAutoRefreshToken = (onUnauthorized: () => void, onTokenRefreshed: () => void) => {
  const onRefresh = useCallback(() => autoRefreshToken(onUnauthorized, onTokenRefreshed), [onUnauthorized]);

  useEffect(() => {
    const timerId = setInterval(onRefresh, TIMEOUT_REFRESH_TOKEN);

    return () => clearInterval(timerId);
  }, [onRefresh]);
};

const useUser = (providers: any[], locale?: string, authSensitiveKeys?: string[]) => {
  const cacheRef = useRef<CustomCache>(null);
  const [isReady, setReady] = useState(false);
  const [isAuthorized, setAuthorized] = useState(false);

  const isSeller = global.__env?.IS_PRODUCTION
    ? global.location?.origin.startsWith(`${global.__env?.DOMAINE_SELLER}`)
    : global.location?.pathname.startsWith(routesSeller.landing());

  const isAdmin = global.__env?.IS_PRODUCTION
    ? global.location?.origin.startsWith(`${global.__env?.DOMAINE_ADMIN}`)
    : global.location?.pathname.startsWith(routesAdmin.auth());

  const [isBusinessRegistered, setBusinessRegistered] = useState<boolean | undefined>(undefined);

  const onTokenRefreshed = useCallback(() => {
    providers.map((provider) =>
      provider.setAuthHeader(
        typeToken,
        typeToken === "bearer" ? tokenStorage.getBearerToken() : tokenStorage.getCSRFToken()
      )
    );
  }, [providers]);
  useAutoRefreshToken(() => setAuthorized(false), onTokenRefreshed);

  const initHandlerAnonymous = useCallback(() => {
    setReady(true);
  }, []);

  const eraseAuthToken = useCallback(() => {
    tokenStorage.erase();
    providers.map((provider) => provider.removeAuthHeader());

    setAuthorized(false);
  }, [providers]);

  const handleAuthError = useCallback((err: any) => {
    console.warn("initHandler", err);

    if (err?.code === "token_not_valid") {
      eraseAuthToken();
      initHandlerAnonymous();
      return;
    }

    setReady(true);
  }, [eraseAuthToken, initHandlerAnonymous]);

  const initHandler = useCallback(
    (tokenAuth: string) => {
      authRequests
        .tokenVerify({ token: tokenAuth })
        .then(() => {
          providers.map((provider) =>
            provider.setAuthHeader(
              typeToken,
              typeToken === "bearer" ? tokenStorage.getBearerToken() : tokenStorage.getCSRFToken()
            )
          );

          if (isSeller) {
            sellerRequests.getSellerProfile().then((response) => {
              setBusinessRegistered(response?.is_business_registered === true);
              setAuthorized(true);
              setReady(true);
            })
            .catch((err: any) => {
              handleAuthError(err);
            });
          } else if (isAdmin) {
            // Not implemented
            console.warn("useUser(): Admin role not implemented");
          } else {
            authRequests.getProfile().then((response) => {
              setAuthorized(true);
              setReady(true);
            })
            .catch((err: any) => {
              handleAuthError(err);
            });
          }
        })
        .catch((err: any) => {
          handleAuthError(err);
        });
    },
    [handleAuthError, isAdmin, isSeller, providers]
  );

  useEffect(() => {
    if (IS_SSR) {
      return;
    }

    const tokenAuth = tokenStorage.getTokenAuth();

    if (!tokenAuth) {
      deleteCookieAuth();
    }

    if (!!tokenAuth) {
      initHandler(tokenAuth);
    } else {
      const anonymousId = tokenStorage.getTokenAnonymous();

      if (!anonymousId) {
        initHandlerAnonymous();
      } else {
        providers.map((provider) => provider.setAnonymousHeader(anonymousId));
        setReady(true);
      }
    }
  }, [initHandler, initHandlerAnonymous, providers]);

  const setAuthToken = useCallback(
    async (data: { access: string; refresh: string }, remember?: boolean) => {
      authSensitiveKeys?.forEach((key) => {
        cacheRef.current?.delete(key);
      });

      let tokenAccess = "";
      let tokenRefresh = "";

      if (typeToken === "bearer") {
        tokenAccess = data.access;
        tokenRefresh = data.refresh;
      } else {
        tokenAccess = getCookie("csrftoken") || "";
      }

      tokenStorage.set({ token: tokenAccess, tokenRefresh }, remember);
      providers.map((provider) =>
        provider.setAuthHeader(
          typeToken,
          typeToken === "bearer" ? tokenStorage.getBearerToken() : tokenStorage.getCSRFToken()
        )
      );

      const response = isSeller ? await sellerRequests.getSellerProfile() : await authRequests.getProfile();

      if (isSeller) {
        setBusinessRegistered(response?.is_business_registered === true);
      }
      setAuthorized(true);
      setReady(true);
    },
    [authSensitiveKeys, isSeller, providers]
  );

  const SWRConfig = useCallback(
    ({ children }: any) => (
      <CustomSWRConfig>
        <>
          <CacheReset link={cacheRef} />
          {children}
        </>
      </CustomSWRConfig>
    ),
    []
  );

  return useMemo(
    () => ({
      isReady,
      isAuthorized,
      isSeller,
      isBusinessRegistered,
      setAuthToken,
      eraseAuthToken,
      SWRConfig,
    }),
    [isReady, isAuthorized, isSeller, isBusinessRegistered, setAuthToken, eraseAuthToken, SWRConfig]
  );
};

interface CacheReset {
  link: RefObject<Cache<any>>;
}

const CacheReset = ({ link }: CacheReset) => {
  const { cache } = useSWRConfig();

  useEffect(() => {
    // @ts-ignore
    link.current = cache;
  }, [cache, link]);

  return null;
};

export default useUser;
