"use client";
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import cn from "@/libs/cn";
import disableScroll from "@/utils/disableScroll";
import useOverlay from "./useOverlay";
import { wait } from "@/utils/wait";
import PublicIcons from "@/components/basic/PublicIcon";
import Dialog from "./Dialog";

import styles from "./modal.module.scss";

interface ModalOptions {
  isIOS?: boolean;
  elementId?: string;
  preventScroll?: boolean;
  closeOnOverlayClick?: boolean;
}

export interface OptionsModal {
  className?: string;
  classNameHeader?: string;
  isConfirmModal?: boolean;
  title: string | ReactNode;
  message?: string;
  onConfirm?: () => void;
  textOK?: string;
  buttonOkColor?: "primary" | "secondary";
  icon?: JSX.Element;
  iconColor?: "primary" | "secondary" | "info" | "error";
  closeAllPrevious?: boolean;
  breakOpen?: boolean;
  showParent?: boolean;
  hideBackButton?: boolean;
  preventScroll?: boolean;
  smallPadding?: boolean;
  preventMobileAutoScroll?: boolean;
}

export type UseModal = (args: ModalOptions) => {
  modals?: JSX.Element | null;
  open: (component: any, options?: OptionsModal) => any;
  back: () => void;
  close: () => void;
  actions: React.MutableRefObject<{
    open: (component: any, options?: OptionsModal) => void;
    close: () => void;
    back: () => void;
  }>;
  isOpen: boolean;
  Modal: (props: ModalProps) => JSX.Element;
  classNameModalRoot: string;
};

interface PortalProps {
  root: Element | DocumentFragment;
  active?: boolean;
  showAsParent?: boolean;
  className?: string;
  children: ReactNode;
}

const Portal = ({ children, root, active, showAsParent, className }: PortalProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function transition() {
      await wait(0);
      setShow(true);
    }

    transition();
  });

  const cont = useMemo(
    () => (
      <div className={cn(styles.Modal, className, active && show && styles.show, showAsParent && styles.showAsParent)}>
        {children}
      </div>
    ),
    [active, children, className, show, showAsParent]
  );

  return createPortal(cont, root);
};

export interface ModalProps {
  root: Element | DocumentFragment;
  title: ReactNode;
  icon?: (props: any) => JSX.Element;
  showBackButton?: boolean;
  show?: boolean;
  isConfirmModal?: boolean;
  nextModalOption?: OptionsModal;
  options?: OptionsModal;
  component: (props: any) => JSX.Element;
  onClose?: () => void;
  onBack?: () => void;
}

const useModal: UseModal = (args) => {
  const { elementId = "__next", preventScroll = false, closeOnOverlayClick = true } = args;
  const [isOpen, setOpen] = useState(false);
  const [content, setContent] = useState<{ component: any; options?: OptionsModal }[]>([]);
  const actions = useRef({ open: (component: any, options?: OptionsModal) => {}, close: () => {}, back: () => {} });
  const [preventScrollCurrent, setPreventScrollCurrent] = useState(false);

  const open = useCallback(
    // @ts-ignore
    (component: () => JSX.Element, options?: OptionsModal = {}) => {
      if (options.breakOpen) {
        return;
      }

      const prevContent = options.closeAllPrevious ? [] : [...content];

      setContent([...prevContent, { component, options }]);
      setOpen(true);
      if (preventScroll || options.preventScroll) {
        setPreventScrollCurrent(true);
        // @ts-ignore
        disableScroll.on(null, { disableWheel: false });
      }
      // Fix: small scroll - preventing the modal header from being hidden. Only for mobile.
      if (document.documentElement.clientWidth <= 768 && !options.preventMobileAutoScroll) {
        document.body.querySelector("#content")?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    [content, preventScroll]
  );

  const back = useCallback(() => {
    const newContent = content.slice(0, content.length - 1);
    setContent(newContent);

    if (newContent.length < 1) {
      setOpen(false);
      if (preventScrollCurrent) {
        disableScroll.off();
        setPreventScrollCurrent(false);
      }
    }
  }, [content, preventScrollCurrent]);

  const close = useCallback(() => {
    setOpen(false);
    setContent([]);
    if (preventScrollCurrent) {
      disableScroll.off();
      setPreventScrollCurrent(false)
    }
  }, [setOpen, preventScrollCurrent]);

  actions.current.open = open;
  actions.current.back = back;
  actions.current.close = close;

  const onOverlayClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (closeOnOverlayClick) {
        if (content[content.length - 1]?.options?.showParent) {
          actions.current.back();
        } else {
          close();
        }
      }
    },
    [closeOnOverlayClick, content, close]
  );

  const [root, setRoot] = useState<HTMLDivElement | null>(null);

  const contentProps = useMemo(
    () => ({
      openModal: actions.current.open,
      backModal: actions.current.back,
      closeModal: actions.current.close,
    }),
    []
  );

  useOverlay(isOpen, close, { current: root });

  const Modal = useCallback(
    (modalProps: ModalProps) => {
      const {
        root,
        icon: Icon,
        nextModalOption,
        options,
        title,
        showBackButton,
        isConfirmModal,
        show,
        component: Comp,
        onClose,
        onBack,
      } = modalProps;
      const { iconColor, className, classNameHeader, smallPadding } = options || {};

      return (
        <Portal root={root} active={show} showAsParent={nextModalOption?.showParent} className={cn(className, smallPadding && styles.smallPadding)}>
          <div className={cn(styles.header, classNameHeader)}>
            {!!Icon && (
              <Icon
                height={26}
                className={cn(
                  iconColor === "primary" && styles.primary,
                  iconColor === "secondary" && styles.secondary,
                  iconColor === "info" && styles.info,
                  iconColor === "error" && styles.error
                )}
              />
            )}
            <div className={styles.title}>{title}</div>
            {/*{!isConfirmModal && (*/}
            <PublicIcons
              name="close2"
              width={24}
              height={24}
              className={styles.iconClose}
              onClick={() => {
                if (typeof onClose === "function") {
                  onClose();
                } else {
                  close();
                }
              }}
            />
            {/*)}*/}
            {showBackButton && !isConfirmModal && (
              <PublicIcons
                name="arrowShortLeft"
                className={styles.iconBack}
                width={24}
                height={24}
                onClick={() => {
                  if (typeof onBack === "function") {
                    onBack();
                  } else {
                    actions.current.back();
                  }
                }}
              />
            )}
          </div>
          <Comp {...contentProps} />
        </Portal>
      );
    },
    [close, contentProps]
  );

  const modals = useMemo(
    () => (
      <Dialog
        actions={actions}
        closeOnOverlayClick={closeOnOverlayClick}
        setRoot={setRoot}
        showParent={content[content.length - 1]?.options?.showParent}
        data={content || []}
        renderModal={({ component, options }, viewport, idx) => {
          if (!root) {
            return;
          }

          const nextModalOption = content[idx + 1]?.options;
          // @ts-ignore TODO
          const { title, icon, hideBackButton } = options;
          const isLast = idx === content.length - 1;
          // @ts-ignore TODO
          const isConfirmModal = options.isConfirmModal;

          return (
            <Modal
              key={`modal-${idx}`}
              root={root}
              title={title}
              icon={icon}
              showBackButton={idx > 0 && !hideBackButton && !isConfirmModal}
              show={isLast}
              isConfirmModal={isConfirmModal}
              nextModalOption={nextModalOption}
              component={component}
              options={options}
            />
          );
        }}
      />
    ),
    [Modal, closeOnOverlayClick, content, root]
  );

  return useMemo(
    () => ({
      modals: isOpen ? modals : null,
      open: (component: any, options?: OptionsModal) => actions.current.open(component, options),
      close: () => actions.current.close(),
      back: () => actions.current.back(),
      actions,
      isOpen,
      Modal,
      classNameModalRoot: styles.dialog,
    }),
    [Modal, isOpen, modals]
  );
};

export default useModal;
