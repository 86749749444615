"use client";
import { useEffect, useState } from "react";

/*
    Insert a lazy load script at the bottom of the body
    <Script src="/assets/js/ua-parser.min.js" strategy="lazyOnload" />
*/

const useDetectDevice = () => {
  const [ua, setUA] = useState<{ isMacOS: boolean; isIOS: boolean; isSafari: boolean } | null>(null);

  useEffect(() => {
    let timer: any = null;

    function init() {
      // @ts-ignore
      if (typeof UAParser === "function") {
        // @ts-ignore
        const uap = new UAParser();
        const os = uap.getOS();
        const browser = uap.getBrowser();

        setUA({
          isMacOS: os.name === "macOS",
          isIOS: os.name === "iOS",
          isSafari: browser.name === "Mobile Safari" || browser.name === "Safari",
        });
      }
    }

    if (!ua) {
      timer = setInterval(init, 1000);
    }

    return () => clearInterval(timer);
  }, [ua]);

  return ua;
};

export default useDetectDevice;
