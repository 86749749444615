'use client';
import { ReactNode } from 'react';
import cn from '@/libs/cn';
import styles from './styles.module.scss';

const runToast = async (
  data: {
    type: 'info' | 'success' | 'warning' | 'error';
    text: string;
    autoClose?: boolean;
    customElement: ReactNode;
    closeButton?: boolean;
    toastId?: number | string;
  },
  action?: 'update'
) => {
  const toast = (await import('react-toastify')).toast;
  const { type, text, autoClose = true, customElement = null, closeButton = true, toastId } = data;

  if (action === 'update') {
    toast[action](toastId || text, { render: customElement || text, type: 'success', isLoading: false });
    return;
  }

  toast[type](customElement || text, {
    className: cn(
      type === 'info' && styles.info,
      type === 'success' && styles.success,
      type === 'warning' && styles.warning,
      type === 'error' && styles.error
    ),
    toastId: toastId || text,
    autoClose: autoClose ? undefined : false,
    closeButton,
  });
};

export interface Toasts {
  info: (
    text: string,
    autoClose?: boolean,
    customElement?: ReactNode,
    closeButton?: boolean,
    toastId?: number | string
  ) => void;
  success: (
    text: string,
    autoClose?: boolean,
    customElement?: ReactNode,
    closeButton?: boolean,
    toastId?: number | string
  ) => void;
  warning: (
    text: string,
    autoClose?: boolean,
    customElement?: ReactNode,
    closeButton?: boolean,
    toastId?: number | string
  ) => void;
  error: (
    text: string,
    autoClose?: boolean,
    customElement?: ReactNode,
    closeButton?: boolean,
    toastId?: number | string
  ) => void;
  update: (
    text: string,
    autoClose?: boolean,
    customElement?: ReactNode,
    closeButton?: boolean,
    toastId?: number | string
  ) => void;
}

const useToasts = (): Toasts => {
  return {
    info: (
      text: string,
      autoClose?: boolean,
      customElement?: ReactNode,
      closeButton?: boolean,
      toastId?: number | string
    ) => runToast({ type: 'info', text, autoClose, customElement, closeButton, toastId }),
    success: (
      text: string,
      autoClose?: boolean,
      customElement?: ReactNode,
      closeButton?: boolean,
      toastId?: number | string
    ) => runToast({ type: 'success', text, autoClose, customElement, closeButton, toastId }),
    warning: (
      text: string,
      autoClose?: boolean,
      customElement?: ReactNode,
      closeButton?: boolean,
      toastId?: number | string
    ) => runToast({ type: 'warning', text, autoClose, customElement, closeButton, toastId }),
    error: (
      text: string,
      autoClose?: boolean,
      customElement?: ReactNode,
      closeButton?: boolean,
      toastId?: number | string
    ) => runToast({ type: 'error', text, autoClose, customElement, closeButton, toastId }),
    update: (
      text: string,
      autoClose?: boolean,
      customElement?: ReactNode,
      closeButton?: boolean,
      toastId?: number | string
    ) => runToast({ type: 'error', text, autoClose, customElement, closeButton, toastId }, 'update'),
  };
};

export default useToasts;
