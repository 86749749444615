import { useCallback, useMemo } from "react";
import useViewport, { ViewportType } from "@/hooks/useViewport";
import styles from "./modal.module.scss";

import { OptionsModal } from "./useModal";

const MOBILE_MARGIN_TOP = 40;

interface DialogProps {
  closeOnOverlayClick: boolean;
  showParent?: boolean;
  setRoot: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  actions: React.MutableRefObject<{
    open: (component: any, options?: OptionsModal) => void;
    close: () => void;
    back: () => void;
  }>;
  //   children: any;
  data: {
    component: any;
    options?: OptionsModal | undefined;
  }[];
  renderModal: (
    data: { component: any; options?: OptionsModal | undefined },
    viewport: ViewportType,
    idx: number
  ) => any;
}

const Dialog = (props: DialogProps) => {
  const { closeOnOverlayClick, showParent, setRoot, actions, data, renderModal } = props;

  const onOverlayClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (closeOnOverlayClick) {
        if (showParent) {
          actions.current.back();
        } else {
          actions.current.close();
        }
      }
    },
    [closeOnOverlayClick, showParent, actions]
  );

  const viewport = useViewport();

  const marginTop = useMemo(() => {
    const { height, width } = viewport;
    if (!height || !width) {
      return MOBILE_MARGIN_TOP;
    }

    // if (height / width < 1.3) {
    //   return 0;
    // }

    return MOBILE_MARGIN_TOP;
  }, [viewport]);

  return (
    <div role="dialog" aria-modal className={styles.dialog}>
      <div onClick={onOverlayClick} className={styles.overlay} />
      <div
        ref={(el) => setRoot(el)}
        className={styles.wrapContent}
        style={{
          // top: marginTop,
          minHeight: marginTop && viewport.height ? viewport.height : undefined,
        }}
      >
        {data.map((content, idx) => renderModal(content, viewport, idx))}
      </div>
    </div>
  );
};

export default Dialog;
