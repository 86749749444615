"use client";
import { useMemo, useState } from "react";
import dynamic from "next/dynamic";

import { PropsGlobalProvider } from "./context-types";
import { dataProviderCommon, dataProviderSeller } from "services/crud";
import { useInterface as useAuthInterface } from "@/modules/auth/interface";
import { useInterface as useCartInterface } from "@/modules/cart/interface";

import useModal from "@/libs/modal/useModal";
import useUser from "@/hooks/useUser";
import useSession from "@/hooks/useSession";
import useToasts from "@/libs/toasts/useToasts";
import useTranslate from "@/hooks/useTranslate";
import useLanguages from "@/hooks/useLanguages";
import useCurrency from "@/hooks/useCurrency";
import LoaderCircle from "@/controls/LoaderCircle";
import useDetectDevice from "@/hooks/useDetectDevice";
import useDetectDeviceWidth from "@/hooks/useDetectDeviceWidth";
import apiProfile from "@/modules/profile/constants/apiProfile";
import apiAuth from "@/modules/auth/constants/apiAuth";

import { globalContext as Context } from "./ClientContext";

import useConfirm from "@/libs/modal/confirm/useConfirm";
import useGeolocation from "@/hooks/useGeolocation";
import useLocale from "@/hooks/useLocale";
import { useDataPublicLanguages } from "@/modules/public/hooks/useData";
import { defaultLocale } from "./i18n";
import Preloader from "@/components/basic/Preloader";

const ToastContainer = dynamic(() => import("@/libs/toasts/ToastContainer"), {
  ssr: false,
});

/*
  This place for Dependency Injections of modules
*/

const providers = [dataProviderCommon, dataProviderSeller];

const GlobalProvider = ({ children, init, target, waitFullLoading, waitDictLoading, }: PropsGlobalProvider) => {
  const langs = useDataPublicLanguages();
  const defaultCode = langs.data.find((i) => i.is_main)?.code || defaultLocale;

  const {
    serverIsMobileSize,
    dictionary,
    locales = langs.isLoading ? [init.locale || defaultCode] : langs.data.map((i) => i.code),
  } = init;
  const deviceInfo = useDetectDevice();
  const isMacOS = deviceInfo?.isMacOS;
  const isIOS = deviceInfo?.isIOS || false;
  const isMediumHeight = false;
  const isSafari = deviceInfo?.isSafari || false;
  const isTouchable = !!(global?.navigator?.maxTouchPoints && global.navigator.maxTouchPoints > 0);

  const [keyUpdate, setKeyUpdate] = useState(0);
  const locale = useLocale(locales, init.locale || defaultCode, keyUpdate);
  providers.map((provider) => provider.setLanguageHeader(locale));

  const { isMobileSize: clientIsMobileSize, isSmallSize, deviceSize } = useDetectDeviceWidth(serverIsMobileSize);

  const authSensitiveKeys = useMemo(
    () => [apiAuth.profile(), apiProfile.favoriteProducts(locale), apiProfile.favoriteStores(locale)],
    [locale]
  );
  const { isReady, isAuthorized, isSeller, setAuthToken, eraseAuthToken, SWRConfig, isBusinessRegistered } = useUser(
    providers,
    locale,
    authSensitiveKeys
  );

  const serverProps = useMemo(() => ({ locale, locales: init.locales }), [init.locales, locale]);
  const languages = useLanguages(serverProps, langs.data, setKeyUpdate);
  const { translate, loading: dictLoading } = useTranslate(locale, dictionary, target);

  const { modals, actions, back, close, open, isOpen, Modal, classNameModalRoot } = useModal({ isIOS });

  const confirm = useConfirm(actions, translate);
  const toast = useToasts();
  const geo = useGeolocation(true);

  // Initialize session for use cart of anonymous user
  useSession(isSeller);

  const currency = useCurrency();

  const propsInterface = useMemo(
    () => ({
      currency: currency.value,
      locale,
      translate,
      isAuthorized,
      isReady,
      isSeller,
      isBusinessRegistered,
      eraseAuthToken,
      toast,
      openModal: open,
      backModal: back,
      closeModal: close,
      openConfirm: confirm,
    }),
    [
      currency.value,
      locale,
      translate,
      isAuthorized,
      isReady,
      isSeller,
      isBusinessRegistered,
      eraseAuthToken,
      toast,
      open,
      back,
      close,
      confirm,
    ]
  );

  // import interface of modules
  const auth = useAuthInterface(propsInterface);
  const cart = useCartInterface(propsInterface);

  const value = useMemo(() => {
    return {
      settings: {
        public: {
          hideProductsFilter: false,
        },
        seller: {
          allowMediaGallery: false,
        },
      },
      geo,
      locale,
      locales,
      setAuthToken,
      eraseAuthToken,
      isReady,
      isAuthorized,
      isSeller,
      isBusinessRegistered,
      isMobileSize: clientIsMobileSize,
      isSmallSize,
      deviceSize,
      isTouchable,
      serverIsMobileSize,
      isMacOS,
      isIOS,
      isSafari,
      isMediumHeight,
      translate,
      toast,
      modules: {
        auth,
        cart,
      },
      languages,
      currency,
      openModal: open,
      backModal: back,
      closeModal: close,
      openConfirm: confirm,
      Modal,
      classNameModalRoot,
      providers: {
        common: dataProviderCommon.instance,
        seller: dataProviderSeller.instance,
      },
    };
  }, [
    geo,
    locale,
    locales,
    setAuthToken,
    eraseAuthToken,
    isReady,
    isAuthorized,
    isSeller,
    isBusinessRegistered,
    clientIsMobileSize,
    isSmallSize,
    deviceSize,
    isTouchable,
    serverIsMobileSize,
    isMacOS,
    isIOS,
    isSafari,
    isMediumHeight,
    translate,
    toast,
    auth,
    cart,
    languages,
    currency,
    open,
    back,
    close,
    confirm,
    Modal,
    classNameModalRoot,
  ]);

  const isLoading = waitFullLoading && (langs.isLoading || (waitDictLoading && dictLoading));

  return (
    <>
      {/* @ts-ignore TODO need to review list currency */}
      <Context.Provider value={value}>
        <SWRConfig>
          <Preloader />
          {isLoading ? <LoaderCircle margin="20vh auto" stretchToSpace /> : children}
          {modals}
          <ToastContainer />
        </SWRConfig>
      </Context.Provider>
    </>
  );
};

export default GlobalProvider;
