import { useMemo } from "react";
import { usePathname } from "next/navigation";
import { getCookie } from "@/utils/cookie";
import routesSeller from "@/modules/seller/constants/routesSeller";
import routesAdmin from "@/modules/admin/constants/routesAdmin";

const LOCALE_COOKIE_NAME = "locale";

const useLocale = (locales: string[], defaultCode: string, keyUpdate?: any) => {
  const pathname = usePathname();
  const isSellerPath = global.__env?.IS_PRODUCTION
    ? global.location?.origin === global.__env.DOMAINE_SELLER
    : pathname?.startsWith(routesSeller.landing());

  const isAdminPath = global.__env?.IS_PRODUCTION
    ? global.location?.origin === global.__env.DOMAINE_ADMIN
    : pathname?.startsWith(routesAdmin.auth());

  const locale = useMemo(() => {
    keyUpdate;

    let code = defaultCode;

    if (isSellerPath) {
      const cval = getCookie(LOCALE_COOKIE_NAME) || "";
      if (locales.includes(cval)) {
        code = cval;
      }
    } else  if (isAdminPath) {
      const cval = getCookie(LOCALE_COOKIE_NAME) || "";
      if (locales.includes(cval)) {
        code = cval;
      }
    } else {
      locales.forEach((lang) => {
        if (pathname?.startsWith(`/${lang}/`)) {
          code = lang;
        }
      });
    }

    return code;
  }, [keyUpdate, defaultCode, isSellerPath, isAdminPath, locales, pathname]);

  return locale;
};

export default useLocale;
