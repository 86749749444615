"use client";
import { useCallback, useEffect, useState } from "react";
import useGeo from "@/hooks/useGeo";
import apiNextSide from "@/constants/apiNextSide";
import { crudFetcherNextApi } from "services/crud";

export interface IUseGeolocation {
  latitude: number;
  longitude: number;
  codeCountry?: string;
}

const KEY_GEO = "geo";
const SPLIT_SYMBOL = ",";
const ROUNDING_VALUE = 3;

const requestMapGeodecode = async (latitude: number, longitude: number) => {
  const url = apiNextSide.geocodeCountry(`${latitude}`, `${longitude}`);

  const data = await crudFetcherNextApi.get({
    url,
  });

  if (data) {
    if (data.status === "OK") {
      const { results } = data;
      const dataCountry = results?.[0]?.address_components.find(
        (i: { long_name: string; short_name: string; types: string[] }) => i.types?.includes("country")
      );

      if (!dataCountry) {
        return;
      }
      const codeCountry = dataCountry.short_name;
      const geoValue = [latitude.toFixed(ROUNDING_VALUE), longitude.toFixed(ROUNDING_VALUE), codeCountry];
      localStorage.setItem(KEY_GEO, geoValue.join(SPLIT_SYMBOL));

      return { codeCountry };
    }
  }
};

const getCountryFromStorage = () => {
  const lastGeoValue = localStorage.getItem(KEY_GEO);

  if (!lastGeoValue) {
    return null;
  }

  const vals = lastGeoValue.split(SPLIT_SYMBOL);
  if (vals.length !== 3) {
    return null;
  }

  const codeCountry = vals[2];

  return codeCountry;
};

const isSame = (latitude: number, longitude: number) => {
  const lastGeoValue = localStorage.getItem(KEY_GEO);

  if (!lastGeoValue) {
    return false;
  }

  const vals = lastGeoValue.split(SPLIT_SYMBOL);
  if (vals.length !== 3) {
    return false;
  }

  if (!latitude || !longitude || typeof latitude !== "number" || typeof longitude !== "number") {
    return false;
  }

  if (vals[0] === `${latitude.toFixed(ROUNDING_VALUE)}` && vals[1] === `${longitude.toFixed(ROUNDING_VALUE)}`) {
    return true;
  }

  return false;
};

const useGeolocation = (disabled?: boolean): IUseGeolocation => {
  const { coords, processing } = useGeo(disabled);
  const { latitude, longitude } = coords || {};
  const [data, setData] = useState({});

  const onGeodecode = useCallback(async () => {
    const { codeCountry } = (await requestMapGeodecode(latitude, longitude)) || {};

    setData({ codeCountry });
  }, [latitude, longitude]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    const code = getCountryFromStorage();
    if (!processing && !isSame(latitude, longitude) && latitude && longitude) {
      onGeodecode();
    } else {
      // TODO UA country mock
      setData({ codeCountry: code || "ua" });
    }
  }, [coords, disabled, latitude, longitude, onGeodecode, processing]);

  // TODO UA country mock
  return { latitude, longitude, codeCountry: "ua", ...data };
};

export default useGeolocation;
