import { MutableRefObject, ReactNode, useCallback } from "react";
import Button from "@/controls/Button";
import cn from "@/libs/cn";
import { TranslateFunc } from "@/hooks/useTranslate";
import { OptionsModal } from "../useModal";

import styles from "./styles.module.scss";

interface useConfirm {
  open: (component: any, options?: OptionsModal) => JSX.Element;
  close: () => void;
  back: () => void;
}

export interface PropsConfirmModal {
  title: string;
  message?: string | ReactNode;
  onConfirm: () => void;
  textOK?: string;
  buttonOkColor?: "primary" | "secondary" | "warning";
  icon?: JSX.Element | any;
  iconColor?: "primary" | "secondary" | "info" | "error" | "black";
  textCancel?: string;
  buttonCancelColor?: "primary" | "secondary" | "warning";
  onCancel?: () => void;
  hideButtonOk?: boolean;
  hideButtonCancel?: boolean;
  confirmClassName?: string;
  btnsRightToLeft?: boolean;
  showParent?: boolean;
  translate?: TranslateFunc;
}

const useConfirm = (
  actions: MutableRefObject<{
    open: (component: any, options?: OptionsModal | undefined) => void;
    close: () => void;
    back: () => void;
  }>,
  translate: TranslateFunc = (text: string) => text
) => {
  const onConfirm = useCallback(
    (callback = () => {}) => {
      actions.current.close();
      callback();
    },
    [actions]
  );

  const confirm = useCallback(
    (args: PropsConfirmModal) => {
      actions.current.open(
        () => (
          <ConfirmModal
            {...args}
            onConfirm={() => {
              onConfirm(args.onConfirm);
            }}
            onCancel={() => {
              if (typeof args.onCancel === "function") {
                args.onCancel();
              }
              actions.current.back();
            }}
            translate={translate}
          />
        ),
        {
          title: "",
          isConfirmModal: true,
          className: styles.confirm,
          classNameHeader: styles.confirmHeader,
          showParent: args.showParent,
        }
      );
    },
    [actions, onConfirm, translate]
  );

  return confirm;
};

const ConfirmModal = (props: PropsConfirmModal) => {
  const { translate = (text) => text } = props;
  const {
    icon: Icon,
    iconColor = "primary",
    title = "",
    message = "",
    textOK = translate("OK"),
    onConfirm,
    buttonOkColor = "primary",
    textCancel = translate("Cancel"),
    buttonCancelColor = "secondary",
    onCancel,
    hideButtonOk,
    hideButtonCancel,
    confirmClassName,
    btnsRightToLeft,
  } = props;

  return (
    <div className={cn(styles.ConfirmModal, confirmClassName)}>
      <div className={styles.header}>
        {Icon && (
          // @ts-ignore
          <Icon
            height={26}
            className={cn(
              iconColor === "primary" && styles.primary,
              iconColor === "secondary" && styles.secondary,
              iconColor === "info" && styles.info,
              iconColor === "error" && styles.error,
              iconColor === "black" && styles.black
            )}
          />
        )}
        <div className={styles.title}>{title}</div>
      </div>

      <div className={styles.message}>{message}</div>
      <div className={styles.wrapButton}>
        {!hideButtonCancel && (
          <Button onClick={onCancel} className={styles.cancel} color={buttonCancelColor}>
            {textCancel}
          </Button>
        )}
        {!hideButtonOk && (
          <Button onClick={onConfirm} color={buttonOkColor} className={cn(!!btnsRightToLeft && styles.okToRight)}>
            {textOK}
          </Button>
        )}
      </div>
    </div>
  );
};

export default useConfirm;
