"use client";
import { useEffect, useState } from "react";

const useDetectUserInteraction = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    function move() {
      setReady(true);
    }

    if (!ready) {
      document.addEventListener("click", move, false);
      document.addEventListener("mousemove", move, false);
      document.addEventListener("scroll", move, false);
    }

    return () => {
      document.removeEventListener("click", move, false);
      document.removeEventListener("mousemove", move, false);
      document.removeEventListener("scroll", move, false);
    };
  }, [ready]);

  return ready;
};

export default useDetectUserInteraction;
