"use client";
import { useEffect } from "react";
import apiNextSide from "@/constants/apiNextSide";
import { crudFetcherNextApi } from "services/crud";

const useSession = (disabled: boolean) => {
  useEffect(() => {
    if (disabled) {
      return;
    }

    crudFetcherNextApi.get({
      url: apiNextSide.initSession(),
    });
  }, [disabled]);
};

export default useSession;
